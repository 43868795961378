import axios from "axios";
import ApiInstance from "./interceptor";
import api from "../config/api";
import settings from "../config/settings";

export const userLoginService = params => ApiInstance.post(`${api.AUTH_LOGIN}`, params);
export const authEmailService = params => ApiInstance.post(`${api.AUTH_EMAIL}`, params);
export const otpService = params => ApiInstance.post(`${api.SEND_OTP}`, params);
export const otpVerificationService = params => ApiInstance.post(`${api.VERIFY_OTP}?referToken=${params?.referToken ? params?.referToken : null}`, params);
// export const otpVerificationService = params => ApiInstance.post(`${api.VERIFY_OTP}?referToken=${referToken}`, params);
export const resetPassService = params => ApiInstance.patch(`${api.RESET_PASS}`, params);
export const onboardingBusinessService = params => ApiInstance.put(`${api.ONBOARDING_BUSINESS}`, params);
export const getBusinessProjectsService = params => ApiInstance.get(`${api.BUSINESS_PROJECTS}?businessId=${params}`);
export const getRecentActivitiesService = query => ApiInstance.get(`${api.RECENT_ACTIVITY}` + query);
export const addProjectService = params => ApiInstance.post(`${api.ADD_PROJECT}`,params);
export const getAllProjectsService = () => ApiInstance.get(`${api.ALL_PROJECTS}`);
export const getProjectByBusinessService = params => ApiInstance.get(`${api.PROJECT_BY_BUSSINESS}?businessId=${params}`);
export const updateProjectService = params => ApiInstance.put(`${api.UPDATE_PROJECT}?projectId=${params.id}`, params);

export const getFavProjectService = params => ApiInstance.get(`${api.FAV_PROJECTS}?businessId=${params}`);
export const addPhaseService = params => ApiInstance.post(`${api.ADD_PHASE}`,params);
export const getPhaseService = params => ApiInstance.get(`${api.GET_PHASE}?businessId=${params}`);
export const updatePhaseService = params => ApiInstance.post(`${api.UPDATE_PHASE}`,params);

export const joinWaitListService = params => ApiInstance.post(`${api.JOIN_WAITLIST}`, params);

export const getStatusService = params => ApiInstance.get(`${api.GET_STATUS}?businessId=${params}`);
export const addStatusService = params => ApiInstance.put(`${api.ADD_STATUS}`,params);

export const getLeadSourceService = params => ApiInstance.get(`${api.GET_LEAD_SOURCE}?businessId=${params}`);
export const addLeadSourceService = params => ApiInstance.put(`${api.ADD_LEAD_SOURCE}`,params);

export const globalSearchService = params => ApiInstance.get(`${api.BUSINESS_SEARCH}?query=${params}`);
export const getClientbyProjectIdService = query => ApiInstance.get(`${api.GET_CLIENT_BY_PROJECTS}${query}`);
export const getMembersbyProjectIdService = query => ApiInstance.get(`${api.GET_MEMBER_BY_PROJECTS}${query}`);
export const getClientbyBusinessIdService = params => ApiInstance.get(`${api.GET_CLIENT_BY_BUSINESS_ID}?businessId=${params}`);

export const getMembersByBusinessIdService = params => ApiInstance.get(`${api.GET_MEMBERS_BY_BUSINESS}?businessId=${params}`);

export const updateClientService = (projectId, clientId, payLoad) => ApiInstance.put(`${api.UPDATE_CLIENT}?projectId=${projectId}&clientId=${clientId}`, payLoad);
export const updateClientService2 = (clientId, payLoad) => ApiInstance.put(`${api.UPDATE_CLIENT}?clientId=${clientId}`, payLoad);
export const createClientService = (params) => ApiInstance.post(`${api.ADD_CLIENT}`, params);
export const addClientToProjectService = (params) => ApiInstance.post(`${api.ADD_CLIENT_TO_PROJECT}`, params);

export const addCRServive = params => ApiInstance.post(`${api.CR_ADD}`, params);
export const invoiceRemindService = params => ApiInstance.post(`${api.INVOICE_REMIND}`, params);

export const addSignersService = params => ApiInstance.post(`${api.ADD_SIGNERS}`, params);
export const getSignersServices = params => ApiInstance.get(`${api.GET_SIGNERS}${params}`);
export const updateDealRoomService = params => ApiInstance.put(`${api.UPDATE_DEALROOM}?dealroomId=${params.dealroomId}`, params);
export const deleteDealRoomService = params => ApiInstance.delete(`${api.DELETE_DEALROOM}/${params}`);

export const retriveSignersServices = params => ApiInstance.get(`${api.RETRIEVE_SIGNER}/${params}`);
export const retriveSignersCRServices = params => ApiInstance.get(`${api.RETRIEVE_SIGNER_CR}/${params}`);
export const retrivePdfServices = params => ApiInstance.get(`${api.RETRIEVE_PDF}/${params}`);


export const getProjectTemplate = () => ApiInstance.get(`${api.GET_PROJECT_TEMPLATES}?projectId=`);
export const getTemplatesService = (query) => ApiInstance.get(api.GET_PROJECT_TEMPLATES + query);
export const getChangeRequestList = params => ApiInstance.get(`${api.CR_LIST}${params}`);
export const getFileFoldersService = query => ApiInstance.get(`${api.GET_FILE_FOLDER}` + query);
export const getFileFoldersAllService = query => ApiInstance.get(`${api.GET_FILE_FOLDER_ALL}` + query);
export const getGalleryListService = query => ApiInstance.get(`${api.GET_GALLERY_LIST}` + query);
export const getGalleryService = query => ApiInstance.get(`${api.GET_GALLERY}` + query);
export const updateFileFoldersService = params => ApiInstance.put(`${api.UPDATE_FILE_FOLDER}`, params);

export const getProjectManagementList = params => ApiInstance.get(`${api.PM_LIST}?projectId=${params}`);
export const postProjectManagement = params => ApiInstance.post(`${api.PM_ADD}`, params);
export const updateProjectManagement = (id, params) => ApiInstance.put(`${api.PM_UPDATE}?id=${id}`, params);
export const deleteProjectManagement = params => ApiInstance.delete(`${api.PM_DELETE}/${params}`);

export const getExpensesList = params => ApiInstance.get(`${api.EXP_LIST}${params}`);
export const getExpensesListService = query => ApiInstance.get(`${api.EXP_LIST}${query}`);
export const getExpenseCategory = () => ApiInstance.get(`${api.EXP_GET_CATEGORY}`);
export const onboardSuccessService = (params) => ApiInstance.post(`${api.ONBOARD_SUCCESS}`, params);
export const deleteVendorService = (params) => ApiInstance.delete(api.DELETE_VENDER + params);
export const getMerchandStatusService = (params) => ApiInstance.get(api.GET_MERCHAND_STATUS + params);
export const getMerchandAuthLinkService = (params) => ApiInstance.get(api.MERCHAND_AUTH_LINK + params);


export const generateSignatureService = (params) => ApiInstance.get(api.GENERATE_SIGNATURE_LINK + params);
export const generatePaymentService = (params) => ApiInstance.post(api.GENERATE_PAYEMENT_LINK,  params);




export const updateGalleryService = (query, params, token) => {
    axios.defaults.headers.common["accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    axios.defaults.headers.common["token"] = `${token}`;
    axios.defaults.headers.common["Authorization"] = `${settings.api.AUTH}`;
    return axios.put(api.UPDATE_GALLERY + query, params);
};




export const galleryUpdateFavService = (query, params, token) => {
    axios.defaults.headers.common["accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    axios.defaults.headers.common["token"] = `${token}`;
    axios.defaults.headers.common["Authorization"] = `${settings.api.AUTH}`;
    return axios.put(api.GALLERY_UPDATE_FAV + query, params);
};



export const dealRoomRemindService = (params, token) => {
    axios.defaults.headers.common["accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    axios.defaults.headers.common["token"] = `${token}`;
    axios.defaults.headers.common["Authorization"] = `${settings.api.AUTH}`;
    return axios.post(api.DEALROOM_REMIND, params);
};

export const crRemindService = (params, token) => {
    axios.defaults.headers.common["accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    axios.defaults.headers.common["token"] = `${token}`;
    axios.defaults.headers.common["Authorization"] = `${settings.api.AUTH}`;
    return axios.post(api.CR_REMIND, params);
  };

export const postCreateExpense = (params, token) => {
  axios.defaults.headers.common["accept"] = "application/json";
  axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
  axios.defaults.headers.common["token"] = `${token}`;
  axios.defaults.headers.common["Authorization"] = `${settings.api.AUTH}`;
  return axios.post(api.EXP_CREATE, params);
};
export const updateExpense = (id, params, token) => {
  axios.defaults.headers.common["accept"] = "application/json";
  axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
  axios.defaults.headers.common["token"] = `${token}`;
  axios.defaults.headers.common["Authorization"] = `${settings.api.AUTH}`;
  return axios.put(`${api.EXP_UPDATE}?expenseId=${id}`, params);
};
export const deleteExpenses = params => ApiInstance.delete(`${api.EXP_DELETE}/${params}`);
export const clientDeleteService = params => ApiInstance.delete(`${api.CLIENT_DELETE}/${params}`);
export const clientDeleteFromProjectService = params => ApiInstance.delete(`${api.CLIENT_DELETE_FROM_PROJECT}${params}`);
export const memberDeleteService = params => ApiInstance.delete(`${api.MEMBER_DELETE}/${params}`);
export const memberDeleteFromProjectService = params => ApiInstance.delete(`${api.MEMBER_DELETE_FROM_PROJECT}${params}`);
export const remindExpenses = params => ApiInstance.post(`${api.EXP_REMIND}`, params);

export const postHtmlTemplate = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(api.POST_HTML_TEMPLATES, params)
}

export const postUploadDoc = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(api.POST_UPLOAD_DOC, params)
}

export const sendToPDFService = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(api.SEND_TO_PDF, params)
}

export const sendToPDFForCrService = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(api.SEND_TO_PDF_FOR_CR, params)
}

export const updatePDFService = (id, params) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    // axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(`${api.UPDATE_PDF}?sendToPdfId=${id}`, params)
}


export const updatePDFServiceForCr = (id, params) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    // axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(`${api.UPDATE_PDF_FOR_CR}?createRequestId=${id}`, params)
}

export const _updateDealRoomService = (token, id, params) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(`${api.UPDATE_DEALROOM}?dealroomId=${id}`, params)
}

export const _updateDealRoomServiceCr = (token, id, params) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(`${api.UPDATE_DEALROOM_TO_CR}?createRequestId=${id}`, params);
}

// export const updateDealRoomService = params => ApiInstance.put(`${api.UPDATE_DEALROOM}?dealroomId=${params.dealroomId}`, params);


export const crUploadPdf = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(api.CR_UPLOAD_PDF, params)
}


// export const convertHtmltoPdfService = params => ApiInstance.post(`${api.HTML_TO_PDF}`,params);


// export const convertHtmltoPdfService = params => ApiInstance.post(`${api.HTML_TO_PDF}`,params);


export const _getPhase = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_PHASE}?businessId=${params}`)
}


export const getAllClientsService = (token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_ALL_CLIENTS}`)
}

export const getClientsByBusinessService = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_CLIENTS_BY_BUSINESS}` + params)
}



export const _getStatus = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_STATUS}?businessId=${params}`)
}

export const _getSource = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_LEAD_SOURCE}?businessId=${params}`)
}

export const _addPhase = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(`${api.ADD_PHASE}`,params)
}

export const _addStatus = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(`${api.ADD_STATUS}`,params)
}

export const _addSource = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(`${api.ADD_LEAD_SOURCE}`,params)
}



export const uploadLogoService = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(api.BUSINESS_LOGO, params)
}

export const convertHtmltoPdfService = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(api.HTML_TO_PDF, params)
}


export const uploadToS3Service = (params) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    return axios.post(api.UPLOAD_TO_S3, params)
}

        
export const createOfflineInvoiceRecord = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(`${api.CREATE_OFFLINE_INVOICE_RECORD}`,params)
}

export const getInvoiceList = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_INVOICE_LIST}?projectId=${params}`)
}
export const getInvoiceListService = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_INVOICE_LIST}${params}`)
}

export const getInvoiceOverview = (projectid,businessID, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_INVOICE_OVERVIEW}?businessId=${businessID}&projectId=${projectid}`)
}

export const getInvoiceOverviewService = (query, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_INVOICE_OVERVIEW}${query}`)
}

export const getExpenseOverviewService = (query, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_EXPENSE_OVERVIEW_CHART}${query}`)
}


export const createInvoice = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(`${api.CREATE_INVOICE}`,params)
}


export const sendInvoiceToPDF = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(`${api.INVOICE_TO_PDF}`,params)
}

export const deleteInvoiceService = params => ApiInstance.delete(`${api.DELETE_INVOICE}/${params}`);
export const updateInvoiceService = params => ApiInstance.put(`${api.UPDATE_INVOICE}?invoiceId=${params.invoiceId}`, params);
export const addMemberToProjectService = params => ApiInstance.post(`${api.ADD_MEMBER_TO_PROJECT}`, params);
export const addMemberService = params => ApiInstance.post(`${api.ADD_MEMBER}`, params);
export const getBusinessAcountDetailService = query => ApiInstance.get(`${api.BUSINESS_ACOUNT_DETAIL}` + query);
export const updateBusinessAcountDetailService = (query, params) => ApiInstance.put(`${api.UPDATE_BUSINESS_ACOUNT}` + query, params);
export const createBusinessAcountDetailService = (params) => ApiInstance.post(`${api.CREATE_BUSINESS_ACOUNT}`, params);



// Notes

export const getNotesList = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_NOTES_LIST}${params}`)
}


export const getNotesById = (params,token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_NOTES_LIST_ID}${params}`)
}

export const createNotes = (params,businessId,token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(`${api.CREATE_NOTES_LIST}`,params)
}

export const UpdateNotes = (params,id,token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(`${api.UPDATE_NOTES_LIST}?noteId=${id}`,params)
}

export const DeleteNotes = (params,token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.delete(`${api.DELETE_NOTES_LIST}${params}`)
}
export const deleteStatusReportService = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.delete(`${api.DELETE_STATUS_REPORT}${params}`)
}





export const getTasksList = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_TASKS_LIST}${params}`)
}


export const getTaskById = (params,token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_TASKS_LIST_ID}${params}`)
}

export const createTask = (params,token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(`${api.CREATE_TASKS_LIST}`,params)
}

export const UpdateTask = (params,id,token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(`${api.UPDATE_TASKS_LIST}?taskId=${id}`,params)
}

export const DeleteTask = (params,token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.delete(`${api.DELETE_TASKS_LIST}${params}`)
}



// Status


export const getStatusReportList = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(`${api.GET_STATUS_REPORT_LIST}${params}`)
}


export const createStatusReport = (projectId,params,token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = "multipart/form-data"
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(`${api.CREATE_STATUS_REPORT}?projectId=${projectId}`,params)
}

export const createTemplateService = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = "multipart/form-data"
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(api.GET_PROJECT_TEMPLATES, params)
}


export const deleteTemplateService = (query, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = "multipart/form-data"
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.delete(api.GET_PROJECT_TEMPLATES + query)
}

export const updateTemplateService = (query, params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = "multipart/form-data"
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(api.UPDATE_TEMPLATES + query, params)
}

export const getVendorsService = (token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = "multipart/form-data"
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(api.GET_VENDORS)
}

export const getVendorsByBusinessService = (query, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = "multipart/form-data"
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(api.GET_VENDORS_BY_BUSINESS + query)
}

export const addVendorsService = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = "multipart/form-data"
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.post(api.ADD_VENDORS, params)
}

export const editVendorsService = (query, params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = "multipart/form-data"
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(api.UPDATE_VENDER + query, params)
}

export const addVendorsPhotoService = (query, params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = "multipart/form-data"
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(api.ADD_VENDER_PHOTO + query, params)
}

export const updateClientPhotoService = (query, params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = "multipart/form-data"
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(api.UPDATE_CLIENT_PHOTO + query, params)
}

export const getUserDetailService = (query, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = "multipart/form-data"
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(api.GET_AUTH_USER_DETAIL + query)
}

export const updateUserProfileService = (params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = "multipart/form-data"
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(api.UPDATE_USER_PROFILE, params)
}

export const updateUserProfilePhotoService = (query, params, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = "multipart/form-data"
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.put(api.UPDATE_USER_PROFILE_PHOTO + query, params)
}


export const getBrandDetailService = (query, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = "multipart/form-data"
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(api.GET_BRAND_DETAIL + query)
}

export const getProjectByIdService = (query, token) => {
    axios.defaults.headers.common['accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = "multipart/form-data"
    axios.defaults.headers.common['token'] = `${token}`;
    axios.defaults.headers.common['Authorization'] = `${settings.api.AUTH}`;
    return axios.get(api.GET_PROJECT_BY_ID + query)
}





