import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import GlobalSearch from "../../components/common/GlobalSearch";
import setting from '../../assets/dashboard/icons/setting.svg';
import { logout } from "../../features/auth/authSlice";
import { persistor } from "../../store";
import AccountDetails from "./AccountDetails";
import { Fragment, useState } from "react";
import { BusinessOutlineIcon, ChatOutlineIcon, FAQOutlineIcon, LockOutlineIcon, PaymentsOutlineIcon, PrivacyPolicyOutlineIcon, SettingsOutlineIcon, TeamOutlineIcon, TermsAndConditionOutlineIcon, UserOutlineIcon } from "../../components/SVG";
import BrandSettings from "./BrandSettings";
import BusinessInformation from "./BusinessInformation";
import TeamMembers from "./TeamMembers";
import PaymentSettings from "./PaymentSettings";

const Settings = () => {
    const userRole = localStorage.getItem('userRole');
    let userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;
    const token = localStorage.getItem('token');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('account');


    const handleLogout = () => {
        localStorage.clear();
        dispatch(logout());
        persistor.purge()
        navigate("/signin");
    };


    return (
        <Fragment>
            <div className="dashboard_upper">
                <GlobalSearch />
                <div className="logout_main_div">
                    <div className="name_intials_dash_upper">
                        {userDetail.firstName ? userDetail.firstName.charAt(0).toUpperCase() : ''}{userDetail.lastName ? userDetail.lastName.charAt(0).toUpperCase() : ''}
                    </div>
                    <div className="logout_div">
                        <p onClick={handleLogout}>Logout</p>
                    </div>
                </div>
            </div>

            <div className="settings_page_wrapper">
                <div className="setting_sidebar">
                    <div className="setting_sidebar_box">
                        <h6>Account Settings</h6>

                        <p onClick={() => setActiveTab('account')} className={activeTab == 'account' ? 'active' : ''}><UserOutlineIcon /><span>Account Details</span></p>
                    </div>
                    {
                        userRole == 'Admin' ? <div className="setting_sidebar_box">
                        <h6>Company Settings <span className="_for_admins"><LockOutlineIcon /> Only For Admins</span></h6>

                        <p onClick={() => setActiveTab('brand')} className={activeTab == 'brand' ? 'active' : ''}><SettingsOutlineIcon /><span>Brand Settings</span></p>
                        <p onClick={() => setActiveTab('business')} className={activeTab == 'business' ? 'active' : ''}><BusinessOutlineIcon /><span>Business Information</span></p>
                        <p onClick={() => setActiveTab('team')} className={activeTab == 'team' ? 'active' : ''}><TeamOutlineIcon /><span>Team</span></p>
                        <p onClick={() => setActiveTab('payments')} className={activeTab == 'payments' ? 'active' : ''}><PaymentsOutlineIcon /><span>Payments</span></p>
                    </div> : ''
                    }

                    

                    <div className="setting_sidebar_box">
                        <h6>General</h6>

                        <p onClick={() => setActiveTab('chat')} className={activeTab == 'chat' ? 'active' : ''}><ChatOutlineIcon /><span>Chat with us</span></p>
                        <p onClick={() => setActiveTab('faq')} className={activeTab == 'faq' ? 'active' : ''}><FAQOutlineIcon /><span>FAQ</span></p>
                        <p onClick={() => setActiveTab('terms-and-condition')} className={activeTab == 'terms-and-condition' ? 'active' : ''}><TermsAndConditionOutlineIcon /><span>Terms & Condition</span></p>
                        <p onClick={() => setActiveTab('privacy-policy')} className={activeTab == 'privacy-policy' ? 'active' : ''}><PrivacyPolicyOutlineIcon /><span>Privacy Policy</span></p>
                    </div>
                </div>

                {activeTab == 'account' ? <AccountDetails /> : ''}
                {activeTab == 'brand' ? <BrandSettings /> : ''}
                {activeTab == 'business' ? <BusinessInformation /> : ''}
                {activeTab == 'team' ? <TeamMembers /> : ''}
                {activeTab == 'payments' ? <PaymentSettings /> : ''}

                
            </div>


            
        </Fragment>
    );
}

export default Settings;
