import { useState, useEffect } from "react";
import Select from "react-select";
import brandLogo from "../../assets/images/logo.png";
import { getProjectByBusinessService } from "../../services";
import GlobalSearch from "../../components/common/GlobalSearch";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/auth/authSlice";
import { persistor } from "../../store";
import ClientOverviewComponent from "./ClientOverviewComponent";
import ClientDealroomComponent from "./ClientDealroomComponent";
import ClientChangeRequestsComponent from "./ClientChangeRequestsComponent";
import ClientProjectManagementComponent from "./ClientProjectManagementComponent";
import ClientInvoicesComponent from "./ClientInvoicesComponent";
import FilesContent from "../projects/FilesContent";
import ClientProjectTeamComponent from "./ClientProjectTeamComponent";
import NotesAndTask from "../projects/notesAndTasks/index"
import ClientExpensesComponent from "./ClientExpensesComponent";

export default function ClientDashboard() {
  const referRole = localStorage.getItem('referRole');
  let userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedProject, setSelectedProject] = useState({});
  const [projectsList, setProjectList] = useState([]);
  const [activeTab, setActiveTab] = useState('Overview');

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
    persistor.purge()
    navigate("/signin");
};


useEffect(() => {
  // Add a state to the history stack
  window.history.pushState(null, null, window.location.href);

  const handleBackButton = (event) => {
    // Push the current state again to prevent going back
    window.history.pushState(null, null, window.location.href);
  };

  // Add the event listener for the back button
  window.addEventListener('popstate', handleBackButton);

  return () => {
    // Cleanup the event listener when the component unmounts
    window.removeEventListener('popstate', handleBackButton);
  };
}, []);


  const getAllProjects = async () => {
    try {
        const res = await getProjectByBusinessService(userDetail?.businessDetail?.businessId);
        let _projects = res.data.responseData.projectData || [];
        if(_projects.length > 0) {
            _projects = _projects.map(item => ({...item, label: item.projectName, value: item.id}))
            setProjectList(_projects);
            if(_projects.length){
              setSelectedProject(_projects[0]);
              localStorage.setItem('projectDetail2', JSON.stringify(_projects[0]))
            }
 
        }
    } catch (error) {
        console.error('Error fetching projects:', error);
    }
};


useEffect(() => {
    if(userDetail?.businessDetail?.businessId){
        getAllProjects();
    }
    
}, [])


  return (
    <div className="client_module">
      <div className="c_header">
        <div className="c_header_inner">
          <div className="c_logo">
            <img src={brandLogo} alt="Xora Logo" />
          </div>
          <div className="c_projects ms-5">
            <Select
              classNamePrefix="cm_ui_select min_300"
              value={selectedProject.value ? selectedProject : ''}
              onChange={e => setSelectedProject(e)}
              options={[...projectsList]}
              placeholder="Select project" /> 
          </div>
          <div className="client_satus">
            <div>Stage : <span className="ms-3" style={{background: selectedProject?.projectStage?.color}}>{selectedProject?.projectStage?.name}</span></div>
            <div className="ms-5">Status : <span className="ms-3" style={{background: selectedProject?.projectStatus?.color}}>{selectedProject?.projectStatus?.name}</span></div>
          </div>
          <div className="c_logout_wrap ms-auto">   
            <GlobalSearch />
            <div className="c_name_top ms-5 me-5">
              {userDetail.firstName ? userDetail.firstName.charAt(0).toUpperCase() : ''}{userDetail.lastName ? userDetail.lastName.charAt(0).toUpperCase() : ''}
              <p onClick={handleLogout}>Logout</p>
            </div>
          
          </div>
        </div>
      </div>


      <ul className="projectdetails_navtab_ul">
        <li className={`projectdetails_navtab_li ${activeTab === 'Overview' ? 'active' : ''}`} onClick={() => setActiveTab('Overview')}><p className="font_16">Overview</p></li>
        <li className={`projectdetails_navtab_li ${activeTab === 'Dealroom' ? 'active' : ''}`} onClick={() => setActiveTab('Dealroom')}><p className="font_16">Dealroom</p></li>
        <li className={`projectdetails_navtab_li ${activeTab === 'Change Requests' ? 'active' : ''}`} onClick={() => setActiveTab('Change Requests')}><p className="font_16">Change Requests</p></li>
        <li className={`projectdetails_navtab_li ${activeTab === 'Project Management' ? 'active' : ''}`} onClick={() => setActiveTab('Project Management')}><p className="font_16">Project Management</p></li>
        <li className={`projectdetails_navtab_li ${activeTab === 'Invoices' ? 'active' : ''}`} onClick={() => setActiveTab('Invoices')}><p className="font_16">Invoices</p></li>
        <li className={`projectdetails_navtab_li ${activeTab === 'Expenses' ? 'active' : ''}`} onClick={() => setActiveTab('Expenses')}><p className="font_16">Expenses</p></li>
        <li className={`projectdetails_navtab_li ${activeTab === 'Files' ? 'active' : ''}`} onClick={() => setActiveTab('Files')}><p className="font_16">Files</p></li>
        <li className={`projectdetails_navtab_li ${activeTab === 'Notes & Tasks' ? 'active' : ''}`} onClick={() => setActiveTab('Notes & Tasks')}><p className="font_16">Notes & Tasks</p></li>
        <li className={`projectdetails_navtab_li ${activeTab === 'Project Team' ? 'active' : ''}`} onClick={() => setActiveTab('Project Team')}><p className="font_16">Project Team</p></li>  
      </ul>

     <div className="c_body">
      {activeTab == 'Overview' ? <ClientOverviewComponent selectedProject={selectedProject} /> : ''}
      {activeTab == 'Dealroom' ? <ClientDealroomComponent selectedProject={selectedProject} /> : ''}
      {activeTab == 'Change Requests' ? <ClientChangeRequestsComponent selectedProject={selectedProject} /> : ''}
      {activeTab == 'Project Management' ? <ClientProjectManagementComponent selectedProject={selectedProject} /> : ''}
      {activeTab == 'Invoices' ? <ClientInvoicesComponent selectedProject={selectedProject} /> : ''}
      {activeTab == 'Expenses' ? <ClientExpensesComponent selectedProject={selectedProject} /> : ''}
      {activeTab == 'Files' ? <FilesContent removeDeleteIcon={true} selectedProjectForClient={selectedProject} /> : ''}
      {activeTab == 'Notes & Tasks' ? <NotesAndTask activeTab={true} selectedProject={selectedProject} isGlobal={true}  /> : ''}
      {activeTab == 'Project Team' ? <ClientProjectTeamComponent selectedProject={selectedProject} /> : ''}
     </div>



    </div>
  );
}
