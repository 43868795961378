export const local = {
    api: {
      url: 'http://74.225.254.78:9001/xora/api/v1/',
      AUTH: 'Basic eG9yYTp4b3JhQDEyMw==',
      APP_SITE_URL: "https://devapp.getxora.com",
    },
  };
  
  export const development = {
    api: {
      
      url: 'https://devapi.getxora.com/xora/api/v1/',
      AUTH: 'Basic eG9yYTp4b3JhQDEyMw==',
      APP_SITE_URL: "https://devapp.getxora.com",
    },
  };
  
  export const staging = {
    api: {
      url: "https://stagapi.getxora.com/xora/api/v1/",
      AUTH: "Basic eG9yYTp4b3JhQDEyMw==",
      APP_SITE_URL: "https://stagapp.getxora.com",
    },
  };

  
  export const prod = {
    api: {
      url: "https://api.getxora.com/xora/api/v1/",
      AUTH: "Basic eG9yYTp4b3JhQDEyMw==",
      APP_SITE_URL: "https://app.getxora.com",
    },
  };
  