import { Modal } from "react-bootstrap";
import { getClientbyBusinessIdService, updateClientPhotoService, createClientService, addClientToProjectService, updateClientService2 } from "../../../services";
import { useEffect, useState, useCallback, Fragment } from "react";
import Select from "react-select";
import PhoneNumber from "../../../components/PhoneNumber";
import PenIcon from "../../../assets/logos/pen.svg";
import imageIconOutline from "../../../assets/dashboard/image-icon-outline.svg";
import { separateCountryCodeAndNumber } from "../../../utils/helper";
import useToast from "../../../utils/customHooks/useToast";
import Loader from "../../../components/common/Loader";
import { formatPhoneNumberIntl } from "react-phone-number-input";

const AddClientModal = ({ onHide, cmCallback, isFromGlobal, activeClient }) => {
  const businessDetails = JSON.parse(localStorage.getItem("authUserData")) || null;
  const token = localStorage.getItem("token");
  let projectDetail = JSON.parse(localStorage.getItem("projectDetail"));
  const showToast = useToast()

  const [allClients, setAllClients] = useState([]);
  const [fileErr, setFileErr] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState('');
  const [profilePhotoView, setProfilePhotoView] = useState('');
  const [btnLoader, setBtnLoader] = useState(false);

  const [clientDetails, setClientDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone_number: "",
    address: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone_number: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  useEffect(() => {
    console.log(activeClient, 'activeClientactiveClient')
    setClientDetails({
      firstName: activeClient.firstName,
      lastName: activeClient.lastName,
      email: activeClient.email,
      phone_number: `${activeClient.country_code}${activeClient.phone_number}`,
      address: activeClient.address,
    })
    setProfilePhotoView(activeClient.profilePhoto)
  }, [activeClient])


  const validateFields = () => {
    const newErrors = {};
    if (!clientDetails.firstName) newErrors.firstName = "First name is required.";
    if (!clientDetails.lastName) newErrors.lastName = "Last name is required.";
    if (!clientDetails.email) {
      newErrors.email = "Email is required.";
    } else if (!validateEmail(clientDetails.email)) {
      newErrors.email = "Invalid email format.";
    }
    if (!clientDetails.phone_number) newErrors.phone_number = "Phone number is required.";
    return newErrors;
  };

  const getAllClients = useCallback(async () => {
    const businessId = businessDetails?.businessDetail?.businessId;
    if (businessId) {
      try {
        const res = await getClientbyBusinessIdService(businessId);
        if (res.data.status === 1) {
          const clients = res.data.responseData.clients || [];
          const options = clients.filter(jj => jj.firstName && jj.lastName).map((client) => ({
            value: client.id,
            label: client.clientName,
            ...client,
          }));
          setAllClients(options);
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    }
  }, [businessDetails]);

  useEffect(() => {
    getAllClients();
  }, []);

  const handleClientChange = (selectedOption) => {
    if (selectedOption) {
      setClientDetails({
        firstName: selectedOption.firstName,
        lastName: selectedOption.lastName,
        email: selectedOption.email,
        phone_number: `${selectedOption.country_code}${selectedOption.phone_number}`,
        address: selectedOption.address || "",
      });

      setErrors({
        firstName: "",
        lastName: "",
        email: "",
        phone_number: "",
      });
    } else {
      setClientDetails({
        firstName: "",
        lastName: "",
        email: "",
        phone_number: "",
        address: "",
      });
    }
    setSelectedClient(selectedOption);
  };

  const handleInputChange = (field, value) => {
    setClientDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handleSubmit = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    if(selectedClient?.value){
      setBtnLoader(true);
      let params2 = {
        clientId: selectedClient.value,
        projectId: projectDetail.id
      }

      console.log(selectedClient.value, 'asdsadasdads')

      addClientToProjectService(params2).then((res2) => {
        if (res2.statusCode === 200) {
          if (res2.data.status === 1) {
            showToast('Client Created Successfull', "success");
            setBtnLoader(false);
            onHide();
            cmCallback('clients')
          }
        }
      })

    }else{
      setBtnLoader(true);
      const { cc, number } = separateCountryCodeAndNumber(clientDetails.phone_number || '');
      let params = {
        clientName: `${clientDetails.firstName} ${clientDetails.lastName}`,
        firstName: clientDetails.firstName,
        lastName: clientDetails.lastName,
        country_code: cc,
        phone_number: number,
        address: clientDetails.address,
        isPrimary: 1,
        email: clientDetails.email,
        businessId: businessDetails?.businessDetail?.businessId,
      }
    
      createClientService(params).then(res => {
        if(res?.data?.status){
          let clientId = res?.data?.responseData?.clientData.id;
  
          if(clientId){ 
            
            let params2 = {
              clientId,
              projectId: projectDetail.id
            }
  
            addClientToProjectService(params2).then((res2) => {
              if (res2.statusCode === 200) {
                if (res2.data.status === 1) {
                  showToast('Client Created Successfull', "success");
                  setBtnLoader(false);
                  onHide();
                  cmCallback('clients')
                }
              }
            })
          }
  
        }else{
          setBtnLoader(false);
        }
      })

    }




   



    
  };


  const handleFileChange = async (event) => {
    setFileErr('');
    const file = event.target.files[0];
    const fileSizeInMB = file.size / (1024 * 1024);
    if (fileSizeInMB > 50) {
    setFileErr('File size exceeds 50 MB. Please upload a smaller image.');
    return;
    }


    setProfilePhoto(file);
    if (file) {
      const previewUrl = await getPreviewUrl(file);
      setProfilePhotoView(previewUrl);
    }
  };


  const getPreviewUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };



  const handleValidate = () => {
    let validate = true;


    


    return validate;
  }



  const handleSubmitForGlobal = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }else{
      setBtnLoader(true);
      const { cc, number } = separateCountryCodeAndNumber(formatPhoneNumberIntl(clientDetails.phone_number) || '');


      let params = {
        clientName: `${clientDetails.firstName} ${clientDetails.lastName}`,
        firstName: clientDetails.firstName,
        lastName: clientDetails.lastName,
        country_code: cc,
        phone_number: number,
        address: clientDetails.address,
        email: clientDetails.email,
        businessId: businessDetails?.businessDetail?.businessId,
      }

      if(activeClient.id){
        updateClientService2(activeClient.id, params).then(res => {
          if(res?.data?.status){
            let clientId = res?.data?.responseData?.clientData.id;
  
            if(clientId && profilePhoto){
              const formData = new FormData();
              formData.append('profilePhoto', profilePhoto);
              formData.append('clientId', clientId);
              let query = `?clientId=${clientId}`;
  
              updateClientPhotoService(query, formData, token).then(res2 => {
                if(res2?.data?.status){
                  showToast('Client Updated Successfull', "success");
                  setBtnLoader(false);
                  onHide();
                  cmCallback('client')
  
                }
              })
  
            }else{
              showToast('Client Created Successfull', "success");
              setBtnLoader(false);
              onHide();
              cmCallback('client')
            }
  
          }else{
            setBtnLoader(false);
          }
        })

      }else{
        createClientService(params).then(res => {
          if(res?.data?.status){
            let clientId = res?.data?.responseData?.clientData.id;
  
            if(clientId && profilePhoto){
              const formData = new FormData();
              formData.append('profilePhoto', profilePhoto);
              formData.append('clientId', clientId);
              let query = `?clientId=${clientId}`;
  
              updateClientPhotoService(query, formData, token).then(res2 => {
                if(res2?.data?.status){
                  showToast('Client Created Successfull', "success");
                  setBtnLoader(false);
                  onHide();
                  cmCallback('client')
  
                }
              })
  
            }else{
              showToast('Client Created Successfull', "success");
              setBtnLoader(false);
              onHide();
              cmCallback('client')
            }
  
          }else{
            setBtnLoader(false);
          }
        })
      }



    }

  }

  return (
    <Modal show={true} onHide={onHide} backdrop="static">
      <Modal.Body className="p-0">
        <div className="_form_lable">
          <h3 className="modal_heading mb-4">{activeClient.id ? 'Edit' : 'Add'} Client</h3>

          {
            !isFromGlobal ? <>
               <div className="form-group">
                  <label>Select client</label>
                  <Select
                    value={selectedClient}
                    onChange={handleClientChange}
                    options={allClients}
                    isClearable
                    placeholder="Select client"
                  />
                </div>

                <div className="line_or my-4"><span>Or</span></div>
            
            </> : <Fragment>
                    <div className="cm_profile_upload">
                            <div className="cm_profile_upload_inner">
                              {profilePhotoView ? <img className="pmm0" src={PenIcon} /> : ''}
                              <input onChange={handleFileChange} type="file" />
                              {profilePhotoView ? <img className="pmm1" src={profilePhotoView} /> : <img className="pmm2" src={imageIconOutline} />}
                            </div>
                          </div>

                        {fileErr && <p className="error-text text-center">{fileErr}</p>}
            </Fragment>
          }

       

          <div className="row mb-4">
            <div className="col-md-6">
              <div className="form-group">
                <label>First Name<span className="_imp_">*</span></label>
                <input
                  value={clientDetails.firstName}
                  type="text"
                  onChange={(e) => handleInputChange("firstName", e.target.value)}
                  className="form-control"
                />
                {errors.firstName && <small className="text-danger">{errors.firstName}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Last Name<span className="_imp_">*</span></label>
                <input
                  value={clientDetails.lastName}
                  type="text"
                  onChange={(e) => handleInputChange("lastName", e.target.value)}
                  className="form-control"
                />
                {errors.lastName && <small className="text-danger">{errors.lastName}</small>}
              </div>
            </div>
          </div>

          <div className="form-group mb-4">
            <label>Email<span className="_imp_">*</span></label>
            <input
              disabled={activeClient.id}
              value={clientDetails.email}
              type="email"
              onChange={(e) => handleInputChange("email", e.target.value)}
              className="form-control"
            />
            {errors.email && <small className="text-danger">{errors.email}</small>}
          </div>

          <div className="form-group mb-4">
            <label>Mobile Number<span className="_imp_">*</span></label>
            <PhoneNumber
              value={clientDetails.phone_number}
              setValue={(value) => handleInputChange("phone_number", value)}
              isError={!!errors.phone_number}
              setError={() => {}}
              isSubmitTouch={true}
            />
            {errors.phone_number && <small className="text-danger">{errors.phone_number}</small>}
          </div>

          <div className="form-group">
            <label>Address<span>(optional)</span></label>
            <input
              value={clientDetails.address}
              type="text"
              onChange={(e) => handleInputChange("address", e.target.value)}
              className="form-control"
            />
          </div>

          <div className="d-flex justify-content-between align-baseline mt-5">
            <span onClick={onHide} className="_btn_transparent_ modal_div_button px-5 cursor-pointer">Cancel</span>
            <span onClick={() => btnLoader ? null : isFromGlobal ? handleSubmitForGlobal() : handleSubmit()} className="_btn_normal_ modal_div_button px-5 cursor-pointer">{btnLoader ? <Loader /> : ''} Submit</span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddClientModal;
