import { useEffect, useState } from "react";
import { getBusinessAcountDetailService, updateBusinessAcountDetailService, createBusinessAcountDetailService } from "../../services";
import useToast from "../../utils/customHooks/useToast";


const BusinessInformation = () => {
    const authUserData = JSON.parse(localStorage.getItem("authUserData")) || null;
    const token = localStorage.getItem('token');
    const showToast = useToast()

    const [hasCreated, setHasCreated] = useState(false)
    const [panNumber, setPanNumber] = useState('')
    const [gstinNumber, setGstinNumber] = useState('')
    const [cinNumber, setCinNumber] = useState('')

    const [panNumberErr, setPanNumberErr] = useState('')
    const [gstinNumberErr, setGstinNumberErr] = useState('')
    const [cinNumberErr, setCinNumberErr] = useState('')

    const [isSubmitting, setIsSubmitting] = useState(false);


    const getBusinessAcountDetail = async () => {
        try {
            let query = `?businessId=${authUserData.businessDetail.businessId}`;
            const res = await getBusinessAcountDetailService(query, token);
            if (res?.data?.status) {
                let _data = res.data.responseData;
                if(_data.panNumber || _data.gstinNumber || _data.cinNumber){
                    setHasCreated(true);
                }
                setPanNumber(_data.panNumber);
                setGstinNumber(_data.gstinNumber);
                setCinNumber(_data.cinNumber);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (authUserData?.businessDetail?.businessId) {
            getBusinessAcountDetail();
        }
    }, []);


    const handleValidate = () => {
        let validate = true;

        if(!panNumber){
            setPanNumberErr('PAN Number is required');
            validate = false
        }

        if(!gstinNumber){
            setGstinNumberErr('GST in Number is required');
            validate = false
        }

        if(!cinNumber){
            setCinNumberErr('CIN Number is required');
            validate = false
        }

        return validate
    }

    const handleSubmit = async () => {
        if(handleValidate()){
            let params = {
                panNumber,
                cinNumber,
                gstinNumber,
                businessId: authUserData.businessDetail.businessId
            }

            setIsSubmitting(true);
            try {
               

                let res = {};

                if(hasCreated){
                    let query = `?businessId=${authUserData.businessDetail.businessId}`;
                    res = await updateBusinessAcountDetailService(query, params);
                    if (res?.data?.status) {
                        showToast(`Your business account details have been successfully updated.`, "success");
                    }
                }else{
                    res = await createBusinessAcountDetailService(params);
                    if (res?.data?.status) {
                        showToast(`Your business account details have been successfully created.`, "success");
                    }
                }
                
            } catch (error) {
                console.error(error);
            }
            setIsSubmitting(false);
        }
    };


    return (
        <div className="setting_inner_page">
            <h3 className="mb-0">Business Information</h3>
            <p className="mb-5">This information is shown on your invoice</p>

            <div className="row">
                <div className="col-lg-5">
                    <div className="form-group mb-4">
                        <label>Permanent Account Number (PAN)</label>
                        <input
                            type="text"
                            value={panNumber}
                            onChange={e => (setPanNumber(e.target.value), setPanNumberErr(''))}
                            className="form-control"
                            placeholder="PAN Number"
                            name="firstName" />

                        {panNumberErr && <span className="cm_err">{panNumberErr}</span>}
                    </div>

                    <div className="form-group mb-4">
                        <label>GSTIN</label>
                        <input
                            type="text"
                            value={gstinNumber}
                            onChange={e => (setGstinNumber(e.target.value), setGstinNumberErr(''))}
                            className="form-control"
                            placeholder="GSTIN Number"
                            name="firstName" />

                        {gstinNumberErr && <span className="cm_err">{gstinNumberErr}</span>}
                    </div>

                    <div className="form-group mb-4">
                        <label>Corporate Information Number (CIN)</label>
                        <input
                            type="text"
                            value={cinNumber}
                            onChange={e => (setCinNumber(e.target.value), setCinNumberErr(''))}
                            className="form-control"
                            placeholder="CIN"
                            name="firstName" />

                        {cinNumberErr && <span className="cm_err">{cinNumberErr}</span>}
                    </div>
                </div>
            </div>

            

            <div className="row">
                <div className="col-lg-5 mt-5 text-end">
                    <button disabled={isSubmitting} className="btn btn-primary px-5" onClick={handleSubmit}>Save</button>
                </div>
            </div>
        </div>
    );
};

export default BusinessInformation;







